import React from "react";
import "./Footer.css";
import ecologo from "./assets/ecologo.png";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-items">
        {/* Logo Section start */}
        <div className="logo-section">
          <img className="logo" src={ecologo} alt="EcoLogo" />
          <p className="description"> <b>Together, we can save the planet.</b></p>
          <p className="description">
  📧 <a href="mailto:connect@ecoindex.ai">connect@ecoindex.ai</a>
</p>

          
          <ul className="social-icons">
            <li>
              <a
                href="https:/x.com/Ecoindex_ai"
                target="blank"
                className="social-link"
              >
                  <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="100"
                  height="100"
                  viewBox="0 0 50 50"
                  fill="currentColor"
                >
                  <path d="M 5.9199219 6 L 20.582031 27.375 L 6.2304688 44 L 9.4101562 44 L 21.986328 29.421875 L 31.986328 44 L 44 44 L 28.681641 21.669922 L 42.199219 6 L 39.029297 6 L 27.275391 19.617188 L 17.933594 6 L 5.9199219 6 z M 9.7167969 8 L 16.880859 8 L 40.203125 42 L 33.039062 42 L 9.7167969 8 z"></path>
                </svg>
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/ecoindex"
                target="blank"
                className="social-link"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="100"
                  height="100"
                  viewBox="0 0 50 50"
                  fill="currentColor"
                >
                  <path d="M41,4H9C6.24,4,4,6.24,4,9v32c0,2.76,2.24,5,5,5h32c2.76,0,5-2.24,5-5V9C46,6.24,43.76,4,41,4z M17,20v19h-6V20H17z M11,14.47c0-1.4,1.2-2.47,3-2.47s2.93,1.07,3,2.47c0,1.4-1.12,2.53-3,2.53C12.2,17,11,15.87,11,14.47z M39,39h-6c0,0,0-9.26,0-10 c0-2-1-4-3.5-4.04h-0.08C27,24.96,26,27.02,26,29c0,0.91,0,10,0,10h-6V20h6v2.56c0,0,1.93-2.56,5.81-2.56 c3.97,0,7.19,2.73,7.19,8.26V39z"></path>
                </svg>
              </a>
              
            </li>
            <li>
              <a
                href="https://www.instagram.com/ecoindex_ai/"
                target="blank"
                className="social-link"
              >
                 <svg
                  className="icon"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                >
                  <path d="M11.999 7.377a4.623 4.623 0 1 0 0 9.248 4.623 4.623 0 0 0 0-9.248zm0 7.627a3.004 3.004 0 1 1 0-6.008 3.004 3.004 0 0 1 0 6.008z"></path>
                  <circle cx="16.806" cy="7.207" r="1.078"></circle>
                  <path d="M20.533 6.111A4.605 4.605 0 0 0 17.9 3.479a6.606 6.606 0 0 0-2.186-.42c-.963-.042-1.268-.054-3.71-.054s-2.755 0-3.71.054a6.554 6.554 0 0 0-2.184.42 4.6 4.6 0 0 0-2.633 2.632 6.585 6.585 0 0 0-.419 2.186c-.043.962-.056 1.267-.056 3.71 0 2.442 0 2.753.056 3.71.015.748.156 1.486.419 2.187a4.61 4.61 0 0 0 2.634 2.632 6.584 6.584 0 0 0 2.185.45c.963.042 1.268.055 3.71.055s2.755 0 3.71-.055a6.615 6.615 0 0 0 2.186-.419 4.613 4.613 0 0 0 2.633-2.633c.263-.7.404-1.438.419-2.186.043-.962.056-1.267.056-3.71s0-2.753-.056-3.71a6.581 6.581 0 0 0-.421-2.217zm-1.218 9.532a5.043 5.043 0 0 1-.311 1.688 2.987 2.987 0 0 1-1.712 1.711 4.985 4.985 0 0 1-1.67.311c-.95.044-1.218.055-3.654.055-2.438 0-2.687 0-3.655-.055a4.96 4.96 0 0 1-1.669-.311 2.985 2.985 0 0 1-1.719-1.711 5.08 5.08 0 0 1-.311-1.669c-.043-.95-.053-1.218-.053-3.654 0-2.437 0-2.686.053-3.655a5.038 5.038 0 0 1 .311-1.687c.305-.789.93-1.41 1.719-1.712a5.01 5.01 0 0 1 1.669-.311c.951-.043 1.218-.055 3.655-.055s2.687 0 3.654.055a4.96 4.96 0 0 1 1.67.311 2.991 2.991 0 0 1 1.712 1.712 5.08 5.08 0 0 1 .311 1.669c.043.951.054 1.218.054 3.655 0 2.436 0 2.698-.043 3.654h-.011z"></path>
                </svg>
              </a>
              
            </li>
          </ul>
        </div>

        {/* Logo Section end */}

        {/* Link Section start */}

        <div className="links-section">
          <div className="link-group">
            <p className="link-group-title">Company</p>
            <ul className="link-list">
              <li>
                <a href="/" className="link">
                  About Us
                </a>
              </li>
              <li>
                <a href="/" className="link">
                  Blog
                </a>
              </li>
              <li>
                <a href="/" className="link">
                  Contact Us
                </a>
              </li>
            </ul>
          </div>
          <div className="link-group">
            <p className="link-group-title">Support</p>
            <ul className="link-list">
              <li>
                <a href="/" className="link">
                  Terms of Service
                </a>
              </li>
              <li>
                <a href="/" className="link">
                  Privacy Policy
                </a>
              </li>
            </ul>
          </div>
          <div className="link-group">
            <p className="link-group-title">Product</p>
            <ul className="link-list">
              <li>
                <a href="/" className="link">
                  Landing Page
                </a>
              </li>
              <li>
                <a href="/" className="link">
                  Features
                </a>
              </li>
              <li>
                <a href="/" className="link">
                  Pricing
                </a>
              </li>
            </ul>
          </div>
        </div>
        {/* Link Section end */}

       
      </div>

     
      <p className="footer-text">&copy; 2024. All rights reserved by EcoIndex.</p>
    </div>
  );
};

export default Footer;
