import { useLocation, Route, Routes } from 'react-router-dom';
import React, { useEffect } from "react";
import EcoIndex from '../../EcoIndex';
import EventDetailsSidebar from '../../eventDetailsSidebar';
import WasteGenaration from '../WasteGeneration/WasteGeneration';
import { useEvent } from "../../EventDetailsContext";
import './PostEventPlanning.css';

const PostEventPlanning = () => {
  const location = useLocation();
  const { eventDetails, setEventDetails } = useEvent();

  useEffect(() => {
    if (location.state?.event) {
      setEventDetails(location.state.event);
    }
  }, [location.state, setEventDetails]);


  return (
    <div className="post-planning">
      <EcoIndex />
      <div className="main-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 d-flex align-items-center mb-3">
              <div class="main-content-header">UPDATE EVENT</div>
            </div>
            <div className="col-md-6 d-flex align-items-center justify-content-end mb-3">
              <h1>{eventDetails?.eventName}</h1>
            </div>
          </div>
          <hr />
          <div className="row p-3">
            <div className="col-md-12 p-0 shadow-box">
              <div className="post-planning-box">
                <div className="row">
                  <div className="col-md-12">
                    <EventDetailsSidebar eventDetails={eventDetails} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="post-planning-content">
                      <Routes>
                        <Route path="waste-generation" element={<WasteGenaration eventDetails={eventDetails} />} />
                      </Routes>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostEventPlanning;