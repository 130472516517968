import React, { useEffect, useState } from 'react';
import { getIndividualEmission } from './apiService';
import AttendeePieChart from "./AttendeePieChart";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

const NumericDisplay = ({ eventId }) => {
  const [emissionData, setEmissionData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEmissionData = async () => {
      setLoading(true);
      try {
        const data = await getIndividualEmission(eventId);

        // Log fetched data for debugging
        console.log('Attendee Total Carbon Emission: ', data);

        // Check if there are multiple "Travel" entries and update the second one to "Accommodation"
        const updatedMainData = data.mainData.map((item, index) => {
          if (item.emissionType === "Travel" && index === 2) {
            return { ...item, emissionType: "Accommodation" };
          }
          return item;
        });

        setEmissionData({ ...data, mainData: updatedMainData });
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchEmissionData();
  }, [eventId]);

  if (loading) return <div className='loader-ecoindex'></div>;
  if (error) return <p>Error fetching data: {error.message}</p>;

  const EmissionSources = ({ emissionData }) => {
    return (
      <div className='row'>
        <div className='col-md-12'>
          {emissionData.mainData.map((item, index) => (
            <div className='row' key={index}>
              <div className="col-md-8"><p>Emissions from {item.emissionType}: </p></div>
              <div className="col-md-4"><p className='font-weight-light'>{item.value} KgCO₂</p></div>
            </div>
          ))}
        </div>
      </div>
    )
  }

  const formatEmission = (value) => Number(value.toFixed(2)).toLocaleString();
  return (
    <div className="row">
      <div className="col-md-8">
        <AttendeePieChart eventId={eventId} />
      </div>
      <div className='col-md-4'>
        <div className="row">
          <div className="col-md-12">
            <div className="DashbordNoBox">
              <div className="dashboard-card-header">
                <div className="dashboard-card-header-title">
                  <h3>My Contribution in Total Carbon Emission</h3>
                </div>
                <div className="dashboard-card-header-image">
                  <p className="dashboard-image">
                    <FontAwesomeIcon icon={faUser} className="icon-shadow" />
                  </p>
                </div>
              </div>
              <div className="col-md-12 dashboard-value">
                <p>
                  {emissionData ? `${formatEmission(emissionData.totalCarbonEmission)} KgCO₂` : "No data available"}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="attendee-emission-card mt-4">
              <div className="attendee-card-header">
                <div className="attendee-card-header-title">
                  <h3>Emission Sources</h3>
                </div>
              </div>
              <div className="col-md-12 attendee-value">
                {emissionData.mainData.length ? (
                  <EmissionSources emissionData={emissionData} />
                ) : <p>No data available</p>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NumericDisplay;
