import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getEventList, deleteEvent, useApi } from "./apiService";
import Dropdown from "react-bootstrap/Dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
  faPencil,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import "../src/assets/styles/bootstrap-4.4.1.css";
import "../src/assets/styles/style.css";
import "./EventsTable.css";
import EcoIndex from "./EcoIndex";
import Select from "react-select";
import { useAuth } from "./AuthProvider";
import CustomAlert from "./CustomAlert";

const EventsTable = () => {
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchEvents, setSearchEvents] = useState("");
  const [selectedRole, setSelectedRole] = useState("All Roles");
  const [currentPage, setCurrentPage] = useState(1);
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState("success");
  const [confirmationAction, setConfirmationAction] = useState(null);
  const [isPastSelected, setIsPastSelected] = useState(false);
  const [showCancel, setShowCancel] = useState(false);
  const eventsPerPage = 10;
  const pastEvents = [];
  const upcomingEvents = [];
  const currentTime = Date.now();
  const { accessToken } = useAuth();
  const api = useApi();
  const [eventToDelete, setEventToDelete] = useState(null);

  const handleGenerateReportClick = (event) => {
    navigate(`/events/report/`, { state: { event } })
  }

  const handleEditEventClick = (event) => {
    if (event.myRole === "Attendee") {
      navigate("/events/individual", { state: { event } });
    } else if (event.myRole === "Organizer") {
      console.log("state of event: ", event);
      navigate("/events/Organizer/Organizer-info", { state: { event } });
    } else {
      navigate("/events/pre-event-planning/pre-planning", { state: { event } });
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleRoleSelect = (event) => {
    setSelectedRole(event);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getEventList();
        setEvents(response);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <div className="loader-ecoindex"></div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const handleDeleteEvent = (event) => {
    if (event.myRole !== "Event Manager") {
      setAlertMessage("Only Event Managers can delete events.");
      setAlertType("warning");
      setShowAlert(true);
      return;
    }

    setEventToDelete(event);
    setAlertMessage("Are you sure you want to delete this event?");
    setAlertType("warning");
    setShowAlert(true);
    setShowCancel(true);
    setConfirmationAction(() => () => confirmDelete(event.eventId));
  };

  const confirmDelete = async (eventId) => {
    try {
      await deleteEvent([eventId]);
      const response = await getEventList();
      setEvents(response);
      setAlertMessage("Event deleted successfully.");
      setAlertType("success");
    } catch (error) {
      console.error("Error deleting event:", error);
      setAlertMessage("An error occurred while deleting the event.");
      setAlertType("error");
    } finally {
      setShowAlert(true);
      setConfirmationAction(null);
      setShowCancel(false);
      setEventToDelete(null);
    }
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
    setEventToDelete(null);
    if (alertType === "success") {
      navigate("/events");
    }
  };

  events.forEach((e) => {
    if (new Date(e.endDate).getTime() < currentTime) {
      pastEvents.push(e);
    } else {
      upcomingEvents.push(e);
    }
  });

  // Pagination logic

  function calculateCurrentList(
    pastEvents,
    upcomingEvents,
    searchEvents,
    isPastSelected,
    currentPage,
    eventsPerPage,
    selectedRole
  ) {
    const indexOfLastEvent = currentPage * eventsPerPage;
    const indexOfFirstEvent = indexOfLastEvent - eventsPerPage;
    let tempList = [];
    if (searchEvents !== "") {
      tempList = [...pastEvents, ...upcomingEvents].filter((item) =>
        item.eventName.toLowerCase().includes(searchEvents.toLowerCase())
      );
      if (selectedRole === "Event Manager") {
        tempList = tempList.filter((item) => item.myRole === "Event Manager");
      } else if (selectedRole === "Attendee") {
        tempList = tempList.filter((item) => item.myRole === "Attendee");
      } else if (selectedRole === "Organizer") {
        tempList = tempList.filter((item) => item.myRole === "Organizer");
      }
      return [
        Math.ceil(tempList.length / eventsPerPage),
        tempList.slice(indexOfFirstEvent, indexOfLastEvent),
      ];
    }

    if (isPastSelected) {
      tempList = [...pastEvents];
    } else {
      tempList = [...upcomingEvents];
    }
    if (selectedRole === "Event Manager") {
      tempList = tempList.filter((item) => item.myRole === "Event Manager");
    } else if (selectedRole === "Attendee") {
      tempList = tempList.filter((item) => item.myRole === "Attendee");
    } else if (selectedRole === "Organizer") {
      tempList = tempList.filter((item) => item.myRole === "Organizer");
    }
    return [
      Math.ceil(tempList.length / eventsPerPage),
      tempList.slice(indexOfFirstEvent, indexOfLastEvent),
    ];
  }

  const [totalPages, currentEvents] = calculateCurrentList(
    pastEvents,
    upcomingEvents,
    searchEvents,
    isPastSelected,
    currentPage,
    eventsPerPage,
    selectedRole
  );

  const CustomDivToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      ref={ref}
      className="popup-option"
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      <span className="threedots" />
    </div>
  ));

  return (
    <div className="new-event-page">
      <EcoIndex />
      <div className="main-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 mb-3">
              <div class="main-content-header">EVENTS</div>
            </div>
            <div className="col d-flex justify-content-end align-items-center mb-3">
              <input
                type="search"
                className="search-events"
                placeholder="&#xF002; Search..."
                value={searchEvents}
                onChange={(e) => setSearchEvents(e.target.value)}
              />
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-md-6 pt-3 mb-3">
              <div class="segmented-control">
                <input
                  type="radio"
                  name="radio2"
                  id="tab-1"
                  checked={!isPastSelected}
                  onClick={(e) => setIsPastSelected(false)}
                />
                <label for="tab-1" class="segmented-control-1">
                  <p>Upcoming Events</p>
                </label>
                <input
                  type="radio"
                  name="radio2"
                  value="4"
                  id="tab-2"
                  checked={isPastSelected}
                  onClick={(e) => setIsPastSelected(true)}
                />
                <label for="tab-2" class="segmented-control-2">
                  <p>Past Events</p>
                </label>
                <div class="segmented-control-color"></div>
              </div>
            </div>
            <div className="col-md-6 d-flex justify-content-end align-items-center pt-3 mb-3">
              <div className="col-md-5 pr-0">
                <Select
                  options={[
                    { value: "All Roles", label: "All Roles" },
                    { value: "Event Manager", label: "Event Manager" },
                    { value: "Attendee", label: "Attendee" },
                    { value: "Organizer", label: "Organizer" },
                  ]}
                  defaultValue={{ label: "All Roles", value: "All Roles" }}
                  onChange={(e) => {
                    handleRoleSelect(e.value);
                  }}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      padding: "1px 13px",
                      borderRadius: "30px",
                      boxShadow:
                        "-.5rem -.5rem 1rem hsl(0 0% 100% / .75), .5rem .5rem 1rem hsl(0 0% 50% / .5)",
                      backgroundColor: "#e4e8f6",
                      border: "1px solid #fff",
                    }),
                    menu: (provided) => ({
                      ...provided,
                      border: "1px solid #fff",
                      borderRadius: "15px",
                      padding: "10px 0",
                      backgroundColor: "#ecf0fb",
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      color: "#2e688d",
                      backgroundColor: state.isFocused ? "#fff" : "#ecf0fb",
                    }),
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive event-table">
                <table className="table mb-0">
                  <thead className="table-header">
                    <tr>
                      <th>Event Name</th>
                      <th>Start Date</th>
                      <th>Event Type</th>
                      <th>Location</th>
                      <th>Total Participants</th>
                      <th>Role</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  {/* <tbody className="table-body"> */}
                  {currentEvents.length > 0 ? (
                    currentEvents.map((item, index) => (
                      <tbody className="table-body" key={item.eventId}>
                        <tr>
                          <td>{item.eventName}</td>
                          <td>{item.startDate}</td>
                          <td>{item.eventType}</td>
                          <td>{item.location}</td>
                          <td>{item.participantCount}</td>
                          <td>{item.myRole}</td>
                          <td>
                            <Dropdown drop="start">
                              <Dropdown.Toggle
                                as={CustomDivToggle}
                                style={{ cursor: "pointer" }}
                              ></Dropdown.Toggle>
                              <Dropdown.Menu className="dropdown-menu">
                                <Dropdown.Item
                                  onClick={() => handleEditEventClick(item)}
                                >
                                  Edit Details
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => handleDeleteEvent(item)}
                                >
                                  Delete Event
                                </Dropdown.Item>
                                <Dropdown.Item
                                  disabled={item.myRole !== "Event Manager"}
                                  onClick={() => handleGenerateReportClick(item)}
                                >
                                  Generate AI Report
                                </Dropdown.Item>
                                <Dropdown.Item
                                  disabled
                                  onClick={() => handleEditEventClick(item)}
                                >
                                  Verify Event
                                </Dropdown.Item>
                                <Dropdown.Item
                                  disabled
                                  onClick={() => handleEditEventClick(item)}
                                >
                                  Offset Event
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      </tbody>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="8" className="no-record">
                        No Record Found
                      </td>
                    </tr>
                  )}
                  {/* </tbody> */}
                </table>
              </div>
            </div>
          </div>
          <div className="row pb-4">
            <div className="col-md-6">
              <div className="PageNo">
                Showing: {currentEvents.length} of{" "}
                {isPastSelected ? pastEvents.length : upcomingEvents.length}{" "}
                events
              </div>
            </div>
            <div className="col-md-6">
              <div className="pagination">
                <button
                  className="page-button"
                  onClick={handlePreviousPage}
                  disabled={currentPage === 1}
                >
                  &lt;
                </button>
                <span>{currentPage}</span>
                <button
                  className="page-button"
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                >
                  &gt;
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showAlert && (
        <CustomAlert
          message={alertMessage}
          type={alertType}
          onClose={handleCloseAlert}
          onConfirm={confirmationAction}
          showCancel={showCancel}
        />
      )}
    </div>
  );
};

export default EventsTable;
