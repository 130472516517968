import React from 'react';
import './MyEventsNavbar.css';

const AddEventNavbar = () => {
  return (
    <div className="my-events-navbar">
      <div className="my-events-text">
        <h2>ADD EVENT</h2>
      </div>
    </div>
  );
};

export default AddEventNavbar;
