import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import 'chart.js/auto';

const TravelModeDoughnutChart = ({ attendeeTravelConsumptions }) => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [
          '#a18cd1',
          '#ff9a9e',
          '#8fd3f4',
          '#a1c4fd',
          '#8de9d5',
          '#fdbb2d',
          '#44a08d',
          '#f4c4f3',
          '#2a5298',
        ],
        borderWidth: 3,
        borderRadius: 8,
        cutout: '85%',
        borderColor: "#e4e8f6",
        hoverBackgroundColor: [
          '#a18cd1',
          '#ff9a9e',
          '#8fd3f4',
          '#a1c4fd',
          '#8de9d5',
          '#fdbb2d',
          '#44a08d',
          '#f4c4f3',
          '#2a5298',
        ],
        hoverBorderWidth: 3,
        hoverBorderColor: "#e4e8f6",
        hoverOffset: 6,
        spacing: 3,
      },
    ],
  });

  const travelModeMappings = {
    1: 'Car - Diesel',
    2: 'Car - Petrol',
    3: 'Car - CNG',
    4: 'Car - Electric',
    5: 'Bus - Fuel-Based',
    6: 'Train',
    7: 'Bus - Electric',
    8: 'Flight - Economy',
    13: 'Flight - Business',
  };

  const options = {
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.label || '';
            if (context.parsed) {
              const formattedValue = context.parsed.toFixed(2); // Format value to two decimal places
              label += `: ${formattedValue} KgCO₂`;
            }
            return label;
          }
        },
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        titleColor: '#ffffff',
        bodyColor: '#ffffff',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
          padding: 18,
          color: '#36454F',
          font: {
            size: 12,
          }
        },
        align: 'start',
      }
    }
  };


  useEffect(() => {
    if (attendeeTravelConsumptions && attendeeTravelConsumptions.length > 0) {
      const travelModeData = {};

      attendeeTravelConsumptions.forEach((consumption) => {
        const mode = travelModeMappings[consumption.travelModeId];
        if (mode) {
          if (!travelModeData[mode]) {
            travelModeData[mode] = 0;
          }
          travelModeData[mode] += consumption.attendeeCount;
        }
      });

      setChartData({
        labels: Object.keys(travelModeData),
        datasets: [
          {
            data: Object.values(travelModeData),
            backgroundColor: [
              '#a18cd1',
              '#ff9a9e',
              '#8fd3f4',
              '#a1c4fd',
              '#8de9d5',
              '#fdbb2d',
              '#44a08d',
              '#f4c4f3',
              '#2a5298',
            ],
            borderWidth: 3,
            borderRadius: 8,
            cutout: '85%',
            borderColor: "#e4e8f6",
            hoverBackgroundColor: [
              '#a18cd1',
              '#ff9a9e',
              '#8fd3f4',
              '#a1c4fd',
              '#8de9d5',
              '#fdbb2d',
              '#44a08d',
              '#f4c4f3',
              '#2a5298',
            ],
            hoverBorderWidth: 3,
            hoverBorderColor: "#e4e8f6",
            hoverOffset: 6,
            spacing: 3,
          },
        ],
      });
    }
  }, [attendeeTravelConsumptions]);

  return (
    <div className='row'>
      <div className='col-md-6'>
        <div className="chart-container">
          <div className='chart-header'>
            <h3>Emissions- Travel Mode Distribution</h3>
          </div>
          <div className='chart-data'>
            <Doughnut data={chartData} options={options} />
          </div>
        </div>
      </div>
      <div className='col-md-6 d-flex align-items-center'>
        <div style={{ flex: 1, overflowX: 'auto' }}>
          <table style={{ width: '100%', borderCollapse: 'collapse', fontSize: '12px', tableLayout: 'fixed' }}>
            <thead>
              <tr>
                <th>Travel Mode</th>
                <th>Attendee Count</th>
              </tr>
            </thead>
            <tbody>
              {attendeeTravelConsumptions && attendeeTravelConsumptions.length > 0 ? (
                attendeeTravelConsumptions.map((consumption) => {
                  const mode = travelModeMappings[consumption.travelModeId] || "Unknown";
                  return (
                    <tr key={consumption.attendeeTravelConsumptionId}>
                      <td>{mode}</td>
                      <td>{consumption.attendeeCount}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="2">No data available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div >
    </div>

  );
};

export default TravelModeDoughnutChart;
