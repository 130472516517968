import React from "react";
import emissionvideo from "./assets/emissionvideo.mp4";
import "./AboutEcoIndex.css";
const AboutEcoIndex = () => {
  return (
    <div className="about-container">
      <div className="video-container">
        <h1>About EcoIndex</h1>
        <video className="responsive-video" height={600} controls autoPlay>
          <source
            src="https://api.ecoindex.ai/media/emissionvideo.mp4"
            type="video/mp4"
          />
        </video>
      </div>
    </div>
  );
};

export default AboutEcoIndex;
