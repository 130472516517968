// Dashboard.js
import React, { useState, useEffect } from "react";
import "./Dashboard.css";
import { useNavigate } from "react-router-dom";
import EcoIndex from "./EcoIndex";
import DoughnutChart from "./DoughnutChart";
import DoughnutChart2 from "./DoughnutChart2";
import DoughnutChart3 from "./DoughnutChart3";
import AttendeeDashboard from "./AttendeeDashboard";
import OrganizerDashboard from "./OrganizerDashboard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faBolt,
  faDroplet,
  faWeightScale,
  faPlus,
  faCaretDown,
} from "@fortawesome/free-solid-svg-icons";
import {
  getDashboardData,
  getEventNameList,
  useApi,
  getEventList,
} from "./apiService";
import co2 from "./assets/co2.png";
import { Doughnut } from "react-chartjs-2";
import Select from "react-select";
import "chart.js/auto";

const Dashboard = () => {
  const navigate = useNavigate();
  const [modalClass, setModalClass] = useState("");
  const [selectedEvent, setSelectedEvent] = useState({});
  const [selectedRole, setSelectedRole] = useState("Event Manager");
  const [dashboardData, setDashboardData] = useState(null);
  const [chart2Data, setChart2Data] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [events, setEvents] = useState([]);
  const api = useApi();
  const [availableroles, setAvailableRoles] = useState([]);

  const roleHierarchy = (roles) => {
    if (roles.includes("Event Manager")) {
      return ["Event Manager", "Organizer", "Attendee"];
    } else if (roles.includes("Organizer")) {
      return ["Organizer", "Attendee"];
    } else if (roles.includes("Attendee")) {
      return ["Attendee"];
    }
    return [];
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getEventList();
        const uniqueRoles = [...new Set(response.map((role) => role.myRole))];
        const availroles = roleHierarchy(uniqueRoles);
        setAvailableRoles(availroles);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleAddEventClick = () => {
    navigate("/new-event");
  };

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        if (selectedRole) {
          console.log("selectedRole", selectedRole);
          let eventList = await getEventNameList(selectedRole);

          if (selectedRole === "Organizer") {
            console.log("o", selectedRole);

            const managerevents = await getEventNameList("Event Manager");

            const mergedEventList = [...eventList, ...managerevents];

            eventList = mergedEventList;
          }

          if (selectedRole === "Attendee") {
            const managerevents = await getEventNameList("Event Manager");

            const organizerEventList = await getEventNameList("Organizer");

            const mergedEventList = [
              ...eventList,
              ...organizerEventList,
              ...managerevents,
            ];

            eventList = mergedEventList;
          }

          setEvents(eventList);

          if (eventList.length > 0) {
            if(selectedEvent) {
              if ((eventList.filter(item=>item.eventId===selectedEvent.eventId)).length===0) {
                setSelectedEvent(eventList[0]);
            }} else {
              setSelectedEvent(eventList[0]);
            }
          }
        }
      } catch (err) {
        console.error("Failed to fetch events:", err);
      }
    };

    fetchEvents();
  }, [selectedRole]);

  useEffect(() => {
    if (selectedEvent && selectedEvent.eventId) {
      const fetchDashboardData = async () => {
        setLoading(true);
        try {
          console.log("Selected Event:", selectedEvent);
          const data = await getDashboardData(selectedEvent.eventId);
          console.log("Dashboard Data: ", data);
          setDashboardData(data);
        } catch (err) {
          setError(err);
        } finally {
          setLoading(false);
        }
      };

      fetchDashboardData();
    }
  }, [selectedEvent]);

  const handleEventSelect = (eventId) => {
    let newSelectedEvent = {
      ...events.filter((e) => e.eventId === eventId)[0],
    };
    setSelectedEvent(newSelectedEvent);
  };

  const handleRoleSelect = (role) => {
    setSelectedRole(role);
  };

  const onHandleChart2Click = (chdata) => {
    setChart2Data({ ...chdata });
    setModalClass("is-open");
  };

  const chartData = {
    labels: chart2Data.label,
    datasets: [
      {
        label: "Carbon Emission",
        data: chart2Data.data,
        backgroundColor: [
          "#a18cd1",
          "#ff9a9e",
          "#8fd3f4",
          "#a1c4fd",
          "#8de9d5",
        ],
        borderWidth: 3,
        borderRadius: 8,
        cutout: "85%",
        borderColor: "#e4e8f6",
        hoverBackgroundColor: [
          "#a18cd1",
          "#ff9a9e",
          "#8fd3f4",
          "#a1c4fd",
          "#8de9d5",
        ],
        hoverBorderWidth: 3,
        hoverBorderColor: "#e4e8f6",
        hoverOffset: 6,
        spacing: 3,
      },
    ],
  };

  const options = {
    plugins: {
      title: {
        display: true,
        text:
          chart2Data.name === "Attendee Emission"
            ? chart2Data.name
            : `Emissions from ${chart2Data.name}`,
        align: "center",
        padding: {
          top: 30,
          bottom: 0,
        },
        font: {
          size: 25,
        },
      },
      tooltip: {
        usePointStyle: true,
        callbacks: {
          label: function (context) {
            let label = " Carbon Emission";
            if (context.parsed) {
              const formattedValue = context.parsed.toFixed(2); // Format value to two decimal places
              label += `: ${formattedValue} KgCO₂`;
            }
            return label;
          },
        },
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        borderColor: "rgba(75, 192, 192, 0)",
        borderWidth: 0,
      },
      legend: {
        display: true,
        position: "right",
        labels: {
          padding: 15,
          generateLabels: (chart) => {
            const datasets = chart.data.datasets;
            return datasets[0].data.map((data, i) => ({
              text: ` ${chart.data.labels[i]} : ${data}KgCO₂ (${(
                (data * 100) /
                datasets[0].data.reduce((a, c) => a + c, 0)
              ).toFixed(2)}%)`,
              fillStyle: datasets[0].backgroundColor[i],
              index: i,
            }));
          },
          usePointStyle: true,
          pointStyle: "circle",
          font: {
            size: 12,
          },
        },
        align: "middle",
      },
      maintainAspectRatio: false,
    },
  };

  const plugins = [
    {
      beforeDraw: (chart) => {
        const ctx = chart.ctx;
        const xCoor =
          chart.chartArea.left +
          (chart.chartArea.right - chart.chartArea.left) / 2;
        const yCoor =
          chart.chartArea.top +
          (chart.chartArea.bottom - chart.chartArea.top) / 2;
        ctx.save();
        ctx.font = "12px Rubick, sans-serif";
        ctx.fillStyle = "#2e688dbb";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.fillText(`TOTAL`, xCoor, yCoor - 10);
        ctx.font = "16px Rubik, Lado, sans-serif";
        ctx.fillStyle = "#2e688d";
        ctx.fillText(
          `${chart.data.datasets[0].data
            .reduce((a, c) => a + c, 0)
            .toFixed(2)} KgCO₂`,
          xCoor,
          yCoor + 10
        );
        ctx.restore();
      },
    },
  ];

  return (
    <div className="dashboard">
      <div
        className={`${modalClass} chart-modal`}
        onClick={() => {
          setModalClass("");
        }}
      >
        <div
          className="chart-modal-container"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="chart-modal-content">
            <span
              className="close"
              title="Close"
              onClick={() => setModalClass("")}
            >
              ×
            </span>
            <Doughnut data={chartData} options={options} plugins={plugins} />
          </div>
        </div>
      </div>
      <EcoIndex />
      <div className="main-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 mb-3">
              <div class="main-content-header">DASHBOARD</div>
            </div>
          

   <div className="col-md-6 d-flex justify-content-end mb-3">
              <div className="col-md-5 select-container">
                <Select
                  options={availableroles.map((role) => ({
                    value: role,
                    label: role,
                  }))}
                  defaultValue={{
                    label: "Event Manager",
                    value: "Event Manager",
                  }}
                  onChange={(e) => {
                    handleRoleSelect(e.value);
                  }}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      padding: "1px 13px",
                      borderRadius: "30px",
                      boxShadow:
                        "-.5rem -.5rem 1rem hsl(0 0% 100% / .75), .5rem .5rem 1rem hsl(0 0% 50% / .5)",
                      backgroundColor: "#e4e8f6",
                      border: "1px solid #fff",
                    }),
                    menu: (provided) => ({
                      ...provided,
                      border: "1px solid #fff",
                      borderRadius: "15px",
                      padding: "10px 0",
                      backgroundColor: "#ecf0fb",
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      color: "#2e688d",
                      backgroundColor: state.isFocused ? "#fff" : "#ecf0fb",
                    }),
                  }}
                />
              </div>
              <div className="col-md-5 select-container">
                {events.length > 0 && (
                  <Select
                    options={events.map((event) => ({
                      value: event.eventId,
                      label: event.eventName,
                    }))}
                    key={selectedEvent.eventName}
                    defaultValue={{
                      label: selectedEvent.eventName,
                      value: selectedEvent.eventId,
                    }}
                    onChange={(e) => {
                      handleEventSelect(e.value);
                    }}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        padding: "1px 13px",
                        borderRadius: "30px",
                        boxShadow:
                          "-.5rem -.5rem 1rem hsl(0 0% 100% / .75), .5rem .5rem 1rem hsl(0 0% 50% / .5)",
                        backgroundColor: "#e4e8f6",
                        border: "1px solid #fff",
                        valueContainer: (provided) => ({
                          ...provided,
                          color: "#fff",
                        }),
                      }),
                      menu: (provided) => ({
                        ...provided,
                        border: "1px solid #fff",
                        borderRadius: "15px",
                        padding: "10px 0",
                        backgroundColor: "#ecf0fb",
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        color: "#2e688d",
                        backgroundColor: state.isFocused ? "#fff" : "#ecf0fb",
                      }),
                    }}
                  />
                )}
              </div>
            </div>


          </div>
          <hr />
          <div className="row">
            <div className="col-md-12">
              {loading && <div className='loader-ecoindex'></div>}
              {error && <p>Error loading data</p>}
              {dashboardData && selectedRole === "Event Manager" && (
                <div className="row pt-3 pb-3">
                  <div className="col-md-3">
                    <div className="dashboard-darkbox">
                      <div className="dashboard-card-header">
                        <div className="dashboard-card-header-title">
                          <h3>Total Estimated Carbon Emission</h3>
                        </div>
                        <div className="dashboard-card-header-image">
                          <img src={co2} className="dashboard-image" />
                        </div>
                      </div>
                      <div className="col-md-12 dashboard-value">
                        <p>
                          {dashboardData.totalEstimatedCarbonEmission.toLocaleString()}
                          <span className="value-sub">KgCO₂</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md">
                    <div className="DashbordNoBox">
                      <div className="dashboard-card-header">
                        <div className="dashboard-card-header-title">
                          <h3>Total number of Attendees</h3>
                        </div>
                        <div className="dashboard-card-header-image">
                          <p className="dashboard-image">
                            <FontAwesomeIcon
                              icon={faUser}
                              className="icon-shadow"
                            />
                          </p>
                        </div>
                      </div>
                      <div className="col-md-12 dashboard-value">
                        <p>
                          {dashboardData.totalNoOfAttendee.toLocaleString()}
                          <span className="value-sub">persons</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md">
                    <div className=" DashbordNoBox">
                      <div className="dashboard-card-header">
                        <div className="dashboard-card-header-title">
                          <h3>Emissions due to Energy Usage</h3>
                        </div>
                        <div className="dashboard-card-header-image">
                          <p className="dashboard-image">
                            <FontAwesomeIcon
                              icon={faBolt}
                              className="icon-shadow"
                            />
                          </p>
                        </div>
                      </div>
                      <div className="col-md-12 dashboard-value">
                        <p>
                          {dashboardData.emissionInEnergyConsumption.toLocaleString()}
                          <span className="value-sub">KgCO₂</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md">
                    <div className=" DashbordNoBox">
                      <div className="dashboard-card-header">
                        <div className="dashboard-card-header-title">
                          <h3>Water Consumption</h3>
                        </div>
                        <div className="dashboard-card-header-image">
                          <p className="dashboard-image">
                            <FontAwesomeIcon
                              icon={faDroplet}
                              className="icon-shadow"
                            />
                          </p>
                        </div>
                      </div>
                      <div className="col-md-12 dashboard-value">
                        <p>
                          {dashboardData.emissionInWaterConsumption.toLocaleString()}
                          <span className="value-sub">Litres</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md">
                    <div className="DashbordNoBox">
                      <div className="dashboard-card-header">
                        <div className="dashboard-card-header-title">
                          <h3>Per Capita Emission</h3>
                        </div>
                        <div className="dashboard-card-header-image">
                          <p className="dashboard-image">
                            <FontAwesomeIcon
                              icon={faWeightScale}
                              className="icon-shadow"
                            />
                          </p>
                        </div>
                      </div>
                      <div className="col-md-12 dashboard-value">
                        <p>
                          {dashboardData.perCapitaEmission.toLocaleString()}
                          <span className="value-sub">KgCO₂</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {dashboardData && selectedRole === "Attendee" && (
                <div className="row mt-3">
                  <div className="col">
                    <AttendeeDashboard eventId={selectedEvent.eventId} />
                  </div>
                </div>
              )}
              {dashboardData && selectedRole === "Organizer" && (
                <div className="row mt-3">
                  <div className="col">
                    <OrganizerDashboard eventId={selectedEvent.eventId} />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 pt-3 pb-3">
              {dashboardData &&
                selectedEvent.eventId &&
                selectedRole === "Event Manager" && (
                  <div className="row">
                    <div className="col-md-4">
                      <DoughnutChart
                        key={selectedEvent.eventId}
                        eventId={selectedEvent.eventId}
                        totalEventEmission={
                          dashboardData?.totalEstimatedCarbonEmission
                        }
                      />
                    </div>
                    <div className="col-md-4">
                      <DoughnutChart2
                        key={selectedEvent.eventId}
                        eventId={selectedEvent.eventId}
                        totalEventEmission={
                          dashboardData?.totalEstimatedCarbonEmission
                        }
                        onHandleChart2Click={onHandleChart2Click}
                      />
                    </div>
                    <div className="col-md-4">
                      <DoughnutChart3
                        key={selectedEvent.eventId}
                        eventId={selectedEvent.eventId}
                        totalEventEmission={
                          dashboardData?.totalEstimatedCarbonEmission
                        }
                      />
                    </div>
                  </div>
                )}
            
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
